import React from 'react';
import {
  Box, Chip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import specialtyTypeOfPracticeMobileChipsStyles from './styles';

const SpecialtyTypeOfPracticeMobileChips = ({ specialty, typeOfPractice }) => {
  const classes = specialtyTypeOfPracticeMobileChipsStyles();

  return (
    <Box className={classes.root}>
      {specialty.map(item => (
        <Chip
          key={`${item.key}-specialty`}
          className={`${classes.chip} ${classes.specialtyChip}`}
          variant="outlined"
          size="small"
          label={item.value}
        />
      ))}
      {typeOfPractice.map(item => (
        <Chip
          key={`${item.key}-typeOfPractice`}
          className={`${classes.chip} ${classes.typeOfPracticeChip}`}
          variant="outlined"
          size="small"
          label={item.value}
        />
      ))}
    </Box>
  );
};

SpecialtyTypeOfPracticeMobileChips.propTypes = {
  specialty: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  typeOfPractice: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};

export default SpecialtyTypeOfPracticeMobileChips;
