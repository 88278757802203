import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import honorariumTypes from 'apps/preceptor/constans/honorariumTypes';
import rotationPaymentTypes from 'apps/rotation/constants/rotationPaymentTypes';
import { ROTATION_CONSTANTS } from 'shared/queries/rotation/queries';
import { formatIsoDate } from '../helpers/dateHelpers';

const useRotationPriceCalculator = ({
  profile, trueHours, paperworkDueDate, dateFrom, honorariumHourRanges, paymentType, ignoreLMF,
  discount = 0,
  promoCodeDiscount = 0,
  extraCharges = 0,
  previousRequestsPaidAmount = 0,
}) => {
  const { loading: loadingConstants, data: { rotationConstants = {} } = {} } = useQuery(ROTATION_CONSTANTS);
  const honorarium = useMemo(() => {
    if (profile?.honorariumType?.key === honorariumTypes.flatFee) {
      const honorariumRange = honorariumHourRanges.find(range => trueHours >= range.start && trueHours <= range.end);
      let fee;
      if (honorariumRange) {
        fee = honorariumRange?.amount;
      } else if (honorariumHourRanges.length > 0) {
        fee = honorariumHourRanges[honorariumHourRanges.length - 1]?.amount;
      } else {
        fee = profile.flatFeeForAllHours || 0;
      }
      return fee;
    } if (profile?.honorariumType?.key === honorariumTypes.hourlyRate) {
      return Math.round(Math.max(trueHours * (profile.amount || 0), profile.minimumHonorarium || 0));
    }
    return 0;
  }, [profile, trueHours, honorariumHourRanges]);

  const rotationFee = useMemo(() => {
    if (trueHours) {
      if (profile?.honorariumType?.key === honorariumTypes.noHonorarium) {
        return rotationConstants.plFeeNoHonorarium;
      }
      return honorarium + rotationConstants.plFee;
    }
    return 0;
  }, [profile, trueHours, honorarium, rotationConstants]);

  const msPerDay = 1000 * 60 * 60 * 24;
  const today = new Date(`${formatIsoDate(new Date())}T00:00`);
  let truePaperworkDueDate;
  if (paperworkDueDate) {
    truePaperworkDueDate = new Date(`${formatIsoDate(new Date(paperworkDueDate))}T00:00`);
  } else if (dateFrom) {
    truePaperworkDueDate = new Date(`${dateFrom}T00:00`);
    if (truePaperworkDueDate < today) truePaperworkDueDate = today;
  }
  const daysFromToday = truePaperworkDueDate ? Math.floor((truePaperworkDueDate - today) / msPerDay) : Infinity;

  const hourlyRate = trueHours ? rotationFee / trueHours : 0;
  const serviceFee = Math.round(rotationFee * rotationConstants.serviceFee * 0.01);
  const lastMinuteFee = (ignoreLMF || daysFromToday >= rotationConstants.lastMinuteFeeDaysAmount)
    ? 0
    : rotationConstants.lastMinuteFee;
  const paymentPlanFee = paymentType === rotationPaymentTypes.paymentPlan ? Math.round((rotationFee) * 0.15) : 0;
  const initialTotal = rotationFee + serviceFee + paymentPlanFee + lastMinuteFee + extraCharges;

  let total = initialTotal - discount - previousRequestsPaidAmount - promoCodeDiscount;
  let hideBreakdown = false;
  if (total < 0) {
    total = 0;
    hideBreakdown = true;
  }
  let dueToday = Math.round(initialTotal * 0.25 - previousRequestsPaidAmount);
  if (dueToday < 0) dueToday = 0;
  const dueLater = total - dueToday;

  return {
    honorarium,
    rotationFee,
    hourlyRate,
    serviceFee,
    paymentPlanFee,
    lastMinuteFee,
    total,
    initialTotal,
    dueToday,
    dueLater,
    rotationConstants,
    loadingConstants,
    hideBreakdown,
  };
};

useRotationPriceCalculator.propTypes = {
  profile: PropTypes.shape({
    honorariumType: PropTypes.shape({
      key: PropTypes.number,
      value: PropTypes.string,
    }),
    amount: PropTypes.number,
    minimumHonorarium: PropTypes.number,
    flatFeeForAllHours: PropTypes.number,
  }),
  trueHours: PropTypes.number.isRequired,
  paperworkDueDate: PropTypes.string,
  dateFrom: PropTypes.string.isRequired,
  honorariumHourRanges: PropTypes.arrayOf(PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    amount: PropTypes.number,
  })),
  paymentType: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  discount: PropTypes.number,
  extraCharges: PropTypes.number,
  ignoreLMF: PropTypes.bool,
  previousRequestsPaidAmount: PropTypes.number,
};

useRotationPriceCalculator.defaultProps = {
  paperworkDueDate: null,
  honorariumHourRanges: [],
  paymentType: null,
  discount: 0,
  extraCharges: 0,
  ignoreLMF: false,
  previousRequestsPaidAmount: 0,
};

export default useRotationPriceCalculator;
