import { makeStyles, createStyles } from '@material-ui/core';

const specialtyTypeOfPracticeMobileChipsStyles = makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    gap: theme.spacing(0.5),
    padding: theme.spacing(1),
  },
  chip: {
    '& .MuiChip-label': {
      maxWidth: '100%',
    },
    overflow: 'visible !important',
    borderRadius: theme.spacing(0.75),
  },
  specialtyChip: {
    borderColor: '#1FB97833',
    '& .MuiChip-label': {
      color: '#1FB978',
    },
  },
  typeOfPracticeChip: {
    borderColor: '#1976D233',
    '& .MuiChip-label': {
      color: '#1976D2',
    },
  },
}));

export default specialtyTypeOfPracticeMobileChipsStyles;
