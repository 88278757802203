import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import ButtonProgress from 'shared/components/ButtonProgress';
import clsx from 'clsx';
import blockButtonRotationSearchCardStyles from './styles';

const BlockButtonRotationSearchCard = ({
  handleProfileClick, handleClickAddFavorites, addFavoritesLoading,
  handleClickRemoveFavorites, removeFavoritesDisabled, removeFavoritesLoading, statusFavorite, statusCanceled,
}) => {
  const classes = blockButtonRotationSearchCardStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={isMobile ? 'row' : 'column'}
        mt={isMobile ? 0 : 2}
        mr={isMobile ? 1 : 3}
        ml={isMobile ? 1 : 0}
        gridGap={8}
      >
        <Button
          variant="outlined"
          size="medium"
          fullWidth
          startIcon={<VisibilityIcon fontSize="small" />}
          onClick={handleProfileClick}
          className={clsx({ [classes.profileBtnMobile]: isMobile })}
        >
          View Profile
        </Button>
        {statusFavorite ? (
          <Button
            className={clsx({ [classes.statusCanceled]: statusCanceled, [classes.favoriteBtnMobile]: isMobile })}
            variant={isMobile ? 'contained' : 'outlined'}
            size="medium"
            fullWidth
            startIcon={<DeleteSweepIcon fontSize="small" />}
            onClick={handleClickRemoveFavorites}
            disabled={removeFavoritesDisabled || removeFavoritesLoading}
          >
            Remove
            <ButtonProgress isLoading={removeFavoritesLoading} />
          </Button>
        ) : (
          <Button
            className={clsx({ [classes.statusCanceled]: statusCanceled, [classes.favoriteBtnMobile]: isMobile })}
            variant={isMobile ? 'contained' : 'outlined'}
            size="medium"
            fullWidth
            startIcon={<FavoriteIcon fontSize="small" />}
            onClick={handleClickAddFavorites}
            disabled={removeFavoritesDisabled || addFavoritesLoading}
          >
            Add to Favorites
            <ButtonProgress isLoading={addFavoritesLoading} />
          </Button>
        )}
      </Box>
    </Box>
  );
};

BlockButtonRotationSearchCard.defaultProps = {
  addFavoritesLoading: false,
  removeFavoritesLoading: false,
  statusFavorite: false,
  statusCanceled: false,
};

BlockButtonRotationSearchCard.propTypes = {
  handleProfileClick: PropTypes.func.isRequired,
  handleClickAddFavorites: PropTypes.func.isRequired,
  addFavoritesLoading: PropTypes.bool,
  handleClickRemoveFavorites: PropTypes.func.isRequired,
  removeFavoritesDisabled: PropTypes.bool.isRequired,
  removeFavoritesLoading: PropTypes.bool,
  statusFavorite: PropTypes.bool,
  statusCanceled: PropTypes.bool,
};

export default BlockButtonRotationSearchCard;
