import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useLazyQuery } from '@apollo/client';
import CheckIcon from '@mui/icons-material/Check';
import { Box, FormHelperText, TextField } from '@material-ui/core';
import useDebounce from 'shared/hooks/useDebounce';
import { PROMO_CODES_QUERY } from 'shared/queries/rotation/queries';
import CircularProgressCustom from 'shared/components/CircularProgressCustom';

const PromoCodeField = ({
  rotationDiscount, promoCodeValue, setPromoCodeValue, setPromoCodeDiscount,
}) => {
  const theme = useTheme();

  const debouncedPromoCodeValue = useDebounce(promoCodeValue, 500);

  const [getPromoCodes, { loading: loadingPromoCode, data: { promoCodes } = {} }] = useLazyQuery(
    PROMO_CODES_QUERY, {
      fetchPolicy: 'cache-and-network',
    },
  );

  const activePromoCode = useMemo(() => promoCodes?.find(code => code.isActive), [promoCodes]);
  const promoCodeInactive = useMemo(
    () => !activePromoCode && promoCodes?.find(code => !code.isActive),
    [promoCodes, activePromoCode],
  );

  useEffect(() => {
    if (activePromoCode) {
      setPromoCodeDiscount(activePromoCode.discountAmount);
    } else {
      setPromoCodeDiscount(0);
    }
  }, [activePromoCode, setPromoCodeDiscount]);

  useEffect(() => {
    getPromoCodes({ variables: { code: debouncedPromoCodeValue } });
  }, [getPromoCodes, debouncedPromoCodeValue]);

  const promoCodeEndAdornment = useMemo(() => {
    if (loadingPromoCode) {
      return <CircularProgressCustom size={20} />;
    } if (activePromoCode) {
      return <CheckIcon style={{ color: theme.palette.secondary.main }} />;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPromoCode, activePromoCode]);

  const promoCodeError = useMemo(() => {
    const loading = loadingPromoCode || promoCodeValue !== debouncedPromoCodeValue;
    if (promoCodeInactive && !loading) {
      return 'This promo code has expired.';
    } if (debouncedPromoCodeValue && !activePromoCode && !loading) {
      return 'Promo code does not exist.';
    }
    return null;
  }, [promoCodeInactive, promoCodeValue, activePromoCode, loadingPromoCode, debouncedPromoCodeValue]);

  const debouncedPromoCodeError = useDebounce(promoCodeError, 500);

  return (
    <>
      <TextField
        fullWidth
        variant="filled"
        type="text"
        label="Promo Code"
        labelId="promoCode-code-select-label"
        value={promoCodeValue}
        onChange={e => setPromoCodeValue(e.target.value)}
        InputProps={{ endAdornment: promoCodeEndAdornment }}
        error={Boolean(debouncedPromoCodeError) && !loadingPromoCode}
        disabled={rotationDiscount}
      />
      {rotationDiscount > 0 && (
        <Box ml={2}>
          <FormHelperText variant="contained">
            You can’t use promo code, because you have a discount already.
          </FormHelperText>
        </Box>
      )}
      {debouncedPromoCodeError && (
        <Box ml={2}>
          <FormHelperText error variant="contained">{debouncedPromoCodeError}</FormHelperText>
        </Box>
      )}
    </>
  );
};

PromoCodeField.defaultProps = {
  rotationDiscount: 0,
};

PromoCodeField.propTypes = {
  rotationDiscount: PropTypes.number,
  promoCodeValue: PropTypes.string.isRequired,
  setPromoCodeValue: PropTypes.func.isRequired,
  setPromoCodeDiscount: PropTypes.func.isRequired,
};

export default PromoCodeField;
