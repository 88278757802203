import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const blockButtonRotationSearchCardStyles = makeStyles(() => createStyles({
  statusCanceled: {
    opacity: 0.6,
    filter: 'saturate(0.1) grayscale(0.5)',
  },
  profileBtnMobile: {
    color: '#3FB0AC',
    borderColor: '#3FB0AC',
    '&:hover': {
      color: '#3FB0AC',
      borderColor: '#3FB0AC',
    },
    fontSize: 13,
    '& .MuiSvgIcon-root': {
      color: '#3FB0AC',
      fontSize: 13,
    },
  },
  favoriteBtnMobile: {
    background: '#3FB0AC',
    borderColor: '#3FB0AC',
    '&:hover': {
      background: '#3FB0AC',
      borderColor: '#3FB0AC',
    },
    fontSize: 13,
    '& .MuiSvgIcon-root': {
      fontSize: 13,
    },
  },
}));

export default blockButtonRotationSearchCardStyles;
